import { ReactNode, createContext, useContext } from 'react';
import { FCWithChildren } from '../../../types/FCWithChildren';
import { ChevronIcon, ChevronType } from '../icon/ChevronIcon';
import { Accordion as ReactAccordion, AccordionItem } from '@szhsin/react-accordion';
interface ReactAccordionItemProps {
  header: React.ReactNode;
  disabled?: boolean;
  bodyClassName?: string;
  active?: boolean;
  wrapperClassName?: string;
  highlightedActiveStyle?: boolean;
  titleClassName?: string;
  contentTopBorder?: 'content' | 'header' | 'none';
}

const ReactAccordionItem: FCWithChildren<ReactAccordionItemProps> = ({
  header,
  bodyClassName,
  wrapperClassName,
  highlightedActiveStyle,
  active,
  disabled = false,
  titleClassName,
  contentTopBorder = 'header',
  ...rest
}) => (
  <AccordionItem
    disabled={disabled}
    initialEntered={active}
    {...rest}
    header={({ state: { isEnter } }) => (
      <>
        {header}
        <ChevronIcon
          type={ChevronType.UP}
          className={`${disabled ? 'pointer-events-none' : 'cursor-pointer'} ${isEnter ? 'rotate-180' : ''} ease inline-block h-6 w-6 transform duration-700`}
        />
      </>
    )}
    className={`my-2 flex flex-col ${active && highlightedActiveStyle ? 'shadow-lg' : ''} ${wrapperClassName}`}
    buttonProps={{
      className: () =>
        `${
          disabled ? 'opacity-60' : ''
        } flex min-h-10 items-center text-left w-full justify-between px-4 font-medium transition-colors duration-300 ${titleClassName} ${
          highlightedActiveStyle ? (active ? 'bg-gray-5' : !disabled ? 'hover:bg-gray-5 cursor-pointer' : '') : ''
        } max-w-full ${contentTopBorder === 'header' ? 'border-b' : ''}`,
    }}
    contentProps={{
      className: 'transition-height duration-200 ease-out',
    }}
    panelProps={{ className: `px-4 pb-4 pt-2 ${bodyClassName}` }}
  />
);

interface AccordionProps {
  title: ReactNode | string;
  active?: boolean;
  titleClassName?: string;
  bodyClassName?: string;
  wrapperClassName?: string;
  separationBorder?: 'content' | 'header' | 'none';
  disabled?: boolean;
  highlightedActiveStyle?: boolean;
  onChange?: (open: boolean) => void;
  contentMaxHeight?: number;
}

const AccordionContext = createContext({ updateParentHeight: (_?: number): void => {} });
export const useAccordionContext = () => useContext(AccordionContext);

export const Accordion: FCWithChildren<AccordionProps> = (props) => {
  const {
    title,
    children,
    active = false,
    titleClassName,
    onChange,
    disabled,
    highlightedActiveStyle,
    bodyClassName,
    wrapperClassName,
    separationBorder: contentTopBorder = 'header',
  } = props;

  return (
    <ReactAccordion
      transition
      transitionTimeout={400}
      onStateChange={({ current }) => {
        if (current.isResolved) {
          onChange && onChange(current.isEnter);
        }
      }}
    >
      <ReactAccordionItem
        header={title}
        disabled={disabled}
        active={active}
        bodyClassName={bodyClassName}
        wrapperClassName={wrapperClassName}
        highlightedActiveStyle={highlightedActiveStyle}
        titleClassName={titleClassName}
        contentTopBorder={contentTopBorder}
      >
        <>
          {contentTopBorder === 'content' && <div className="sticky top-0 -mx-4 -mt-2 mb-2 border-t" />}
          {children}
        </>
      </ReactAccordionItem>
    </ReactAccordion>
  );
};

export default Accordion;
